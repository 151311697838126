<template>
    <div class="fangdai">
        <van-nav-bar title="房贷申请" left-text="返回" left-arrow @click-left="$router.go(-1)" :border="true" />
        <van-form @submit="onSubmit">
            <van-field
                input-align="right"
                v-model="formData.Name"
                required
                name="name"
                label="申请人"
                placeholder="申请人"
                :rules="[
                    { required: true, message: '请填写申请人' },
                    { validator: nameValidator, message: '请输入申请人姓名应为2-5个汉字' },
                ]"
            />
            <van-field
                input-align="right"
                v-model="formData.IdCardNo"
                required
                name="IdCardNo"
                label="身份证号码"
                placeholder="身份证号码"
                :rules="[
                    { required: true, message: '请填写身份证号码' },
                    { validator: IdCardNoValidator, message: '身份证号码格式错误' },
                ]"
            />
            <van-field
                input-align="right"
                v-model="formData.Mobile"
                required
                name="Mobile"
                type="tel"
                label="手机号码"
                placeholder="手机号码"
                :rules="[
                    { required: true, message: '请填写手机号码' },
                    { validator: mobileValidator, message: '请输入手机号码' },
                ]"
            />
            <van-field
                input-align="right"
                v-model="formData.code"
                type="number"
                required
                name="code"
                label="验证码"
                placeholder="验证码"
                :rules="[{ required: true, message: '请填写验证码' }]"
            >
                <template #button>
                    <div class="tdjbSelect" :class="time == 0 ? '' : 'disable'" @click.stop="sendCode">
                        发送验证码<span v-if="time > 0">({{ time }}s)</span>
                    </div>
                </template>
            </van-field>
            <van-field
                input-align="right"
                v-model="formData.Money"
                type="number"
                required
                name="Money"
                label="申请额度"
                placeholder="申请额度"
                :rules="[{ required: true, message: '请填写申请额度' }]"
            >
                <template #button>
                    <div>
                        万元
                    </div>
                </template>
            </van-field>
            <van-field
                input-align="right"
                :value="DKYTText"
                readonly
                clickable
                required
                name="DKYT"
                label="用途"
                placeholder="点击选择用途"
                @click="DKYTShowPicker = true"
                :rules="[{ required: true, message: '选择用途' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow" />
                </template>
            </van-field>
            <van-popup v-model="DKYTShowPicker" position="bottom">
                <van-picker show-toolbar :columns="DKYTColumns" @confirm="DKYTConfirm" @cancel="DKYTShowPicker = false" />
            </van-popup>
            <van-field
                input-align="right"
                :value="townText"
                readonly
                clickable
                required
                name="TownId"
                label="所在城镇"
                placeholder="点击选择所在城镇"
                @click="townShowPicker = true"
                :rules="[{ required: true, message: '选择所在城镇' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow" />
                </template>
            </van-field>
            <van-popup v-model="townShowPicker" position="bottom">
                <van-picker show-toolbar :columns="townColumns" @confirm="townConfirm" @cancel="townShowPicker = false" />
            </van-popup>
            <van-field
                input-align="right"
                v-model="formData.villageName"
                required
                name="villageName"
                label="小区名称"
                placeholder="小区名称"
                :rules="[{ required: true, message: '请填写小区名称' }]"
            />
            <van-field
                input-align="right"
                :value="dotText"
                readonly
                clickable
                required
                name="DotId"
                label="受理网点"
                placeholder="点击选择受理网点"
                @click="dotShowPicker = true"
                :rules="[{ required: true, message: '请选择受理网点' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow" />
                </template>
            </van-field>
            <van-popup v-model="dotShowPicker" position="bottom">
                <van-picker show-toolbar :columns="dotColumns" @confirm="dotConfirm" @cancel="dotShowPicker = false" />
            </van-popup>
            <van-field
                input-align="right"
                :value="sysUserText"
                readonly
                clickable
                required
                name="SysUserId"
                label="客户经理"
                placeholder="点击选择客户经理"
                @click="sysUserShowPicker = true"
                :rules="[{ required: true, message: '请选择客户经理' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow" />
                </template>
            </van-field>
            <van-popup v-model="sysUserShowPicker" position="bottom">
                <van-picker show-toolbar :columns="sysUserColumns" @confirm="sysUserConfirm" @cancel="sysUserShowPicker = false" />
            </van-popup>
            <van-field input-align="right" :value="position" readonly name="position" label="网点位置" placeholder="网点位置"> </van-field>

            <!--            <div style="margin: 16px;">-->
            <!--                <van-checkbox v-model="aggree" shape="square" style="font-size: 12px" icon-size="14">阅读并同意《相关条款》</van-checkbox>-->
            <!--            </div>-->
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import { getloadselect, fangdaiwp, sendCode } from "@/api/public";
    import { Toast } from "vant";

    export default {
        name: "fangdai",
        data() {
            return {
                formData: {
                    Name: "",
                    IdCardNo: "",
                    Mobile: "",
                    code: "",
                    Money: "",
                    DKYT: "",
                    TownId: "",
                    villageName: "",
                    DotId: "",
                    SysUserId: "",
                },
                position: "",
                aggree: false,
                DKYTText: "",
                DKYTShowPicker: false,
                DKYTColumns: [
                    {
                        text: "农户种养",
                        Id: "农户种养",
                    },
                    {
                        text: "个体经营",
                        Id: "个体经营",
                    },
                    {
                        text: "公司周转",
                        Id: "公司周转",
                    },
                    {
                        text: "购买房产",
                        Id: "购买房产",
                    },
                    {
                        text: "其它",
                        Id: "其它",
                    },
                ],
                townText: "",
                townShowPicker: false,
                townColumns: [],
                dotText: "",
                dotShowPicker: false,
                dotColumns: [],
                sysUserText: "",
                sysUserShowPicker: false,
                sysUserColumns: [{ text: "请先选择受理网点", disabled: true }],
                time: 0,
            };
        },
        created() {
            this.getloadselect();
        },
        methods: {
            getloadselect() {
                getloadselect()
                    .then((res) => {
                        this.townColumns = res.data.town;
                        this.townColumns.forEach((town) => {
                            town.text = town.TownName;
                        });
                        this.dotColumns = res.data.list;
                        this.dotColumns.forEach((dot) => {
                            dot.text = dot.dotName;
                            dot.users.forEach((user) => {
                                user.text = user.name;
                            });
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
            DKYTConfirm(value) {
                this.DKYTText = value.text;
                this.formData.DKYT = value.Id;
                this.DKYTShowPicker = false;
            },
            townConfirm(value) {
                this.townText = value.text;
                this.formData.TownId = value.Id;
                this.townShowPicker = false;
            },
            dotConfirm(value, index) {
                this.dotText = value.text;
                this.formData.DotId = value.id;
                this.sysUserColumns = this.dotColumns[index].users;
                this.position = this.dotColumns[index].address;
                this.dotShowPicker = false;
            },
            sysUserConfirm(value) {
                this.sysUserText = value.text;
                this.formData.SysUserId = value.id;
                this.sysUserShowPicker = false;
            },
            onSubmit() {
                fangdaiwp(this.formData)
                    .then((res) => {
                        if (res.code == 1) {
                            Toast.success(res.msg);
                            this.$router.push({
                                path: "/index",
                            });
                        } else {
                            Toast.fail(res.msg);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
            mobileValidator(val) {
                return /^1(3|4|5|7|8|9|6)\d{9}$/i.test(val);
            },
            nameValidator(val) {
                return /^[\u4e00-\u9fa5]{2,5}$/.test(val);
            },
            IdCardNoValidator(code) {
                let city = {
                    11: "北京",
                    12: "天津",
                    13: "河北",
                    14: "山西",
                    15: "内蒙古",
                    21: "辽宁",
                    22: "吉林",
                    23: "黑龙江 ",
                    31: "上海",
                    32: "江苏",
                    33: "浙江",
                    34: "安徽",
                    35: "福建",
                    36: "江西",
                    37: "山东",
                    41: "河南",
                    42: "湖北 ",
                    43: "湖南",
                    44: "广东",
                    45: "广西",
                    46: "海南",
                    50: "重庆",
                    51: "四川",
                    52: "贵州",
                    53: "云南",
                    54: "西藏 ",
                    61: "陕西",
                    62: "甘肃",
                    63: "青海",
                    64: "宁夏",
                    65: "新疆",
                    71: "台湾",
                    81: "香港",
                    82: "澳门",
                    91: "国外 ",
                };
                let tip = "";
                let pass = true;

                if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
                    tip = "身份证号格式错误";
                    pass = false;
                } else if (!city[code.substr(0, 2)]) {
                    tip = "地址编码错误";
                    pass = false;
                } else {
                    //18位身份证需要验证最后一位校验位
                    if (code.length == 18) {
                        code = code.split("");
                        //∑(ai×Wi)(mod 11)//加权因子
                        let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                        //校验位
                        let parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
                        let sum = 0;
                        let ai = 0;
                        let wi = 0;
                        for (let i = 0; i < 17; i++) {
                            ai = code[i];
                            wi = factor[i];
                            sum += ai * wi;
                        }
                        if (parity[sum % 11] != code[17]) {
                            tip = "校验位错误";
                            pass = false;
                        }
                    }
                }
                if (!pass) console.log(tip);
                return pass;
            },
            sendCode() {
                if (/^1[3456789]\d{9}$/.test(this.formData.Mobile)) {
                    if (this.time > 0) {
                        return;
                    }
                    this.time = 61;
                    this.sendCodeTime();
                    sendCode({ mobile: this.formData.Mobile })
                        .then((res) => {
                            if (res.code == 1) {
                                Toast.success(res.msg);
                            } else {
                                Toast.fail(res.msg);
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else {
                    Toast.fail("请输入正确手机号码");
                }
            },
            sendCodeTime() {
                this.time--;
                if (this.time > 0) {
                    setTimeout(() => {
                        this.sendCodeTime();
                    }, 1000);
                }
            },
        },
    };
</script>
<style lang="less">
    .fangdai {
        .van-field__error-message {
            text-align: right;
        }
    }

    .tdjbSelect {
        min-width: 1.6rem;
        height: 0.8rem;
        padding: 0 0.21333rem;
        font-size: 0.32rem;
        color: #fff;
        background-color: #07c160;
        border: 0.02667rem solid #07c160;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        margin: 0;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.05333rem;
        cursor: pointer;
        transition: opacity 0.2s;
        -webkit-appearance: none;

        &.disable {
            opacity: 0.5;
        }
    }
</style>
